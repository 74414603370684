<template lang="html">
  <div class="container">
    <div class="row">
      <div class="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 donorbox-container">
        <iframe
          allowpaymentrequest=""
          frameborder="0" height="900px" name="donorbox" scrolling="no"
          seamless="seamless"
          :src="donorbox.url"
          style="max-width: 430px; display:flex; max-height:none!important" width="100%"
        >
        </iframe>
        <div id="loadingMessage" v-if="donorbox.loading">
          <img src="../assets/images/Infinity-loader.gif" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadScript from "../services/loadScript";
import donorboxScript from "../services/donorboxScript";
export default {
  data(){
    return {
      donorbox: {
        url : "//donorbox.org/embed/support-74?default_interval=o&amount=30&show_content=true",
        loading: true
      },

    };
  },
  created() {
    window.scrollTo(0, 0);

    setTimeout(() => {
        this.donorbox.loading = false
    }, 1000)
  },
  mounted(){
    loadScript(()=> {
    }, donorboxScript);
  }
}
</script>

<style lang="css" scoped>
.donorbox-container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loadingMessage {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: absolute;
}
</style>

// script DomElement
// scriptId :  string
const loadScript = (callback, script = null, scriptId = null) => {
  const existingScript = document.getElementById(scriptId);

  if (!existingScript && script) {
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export default loadScript;